import { Grid, IconButton, MenuItem, TextField } from "@mui/material";
import clsx from "clsx";
import ComposeEmail from "components/modal/ComposeEmail";
import ComposeMessage from "components/modal/ComposeMessage";
import EmailTemplate from "components/modal/EmailTemplate";
import { Formik } from "formik";
import React from "react";
import CaseService from "services/caseService";
import * as Yup from "yup";
import write_icon from "../../assets/icons/FontAwsome (edit).png";
import link_icon from "../../assets/icons/FontAwsome (link).png";
import email_icon from "../../assets/icons/FontAwsome (paper-plane).png";
import phone_icon from "../../assets/icons/FontAwsome (phone-alt).png";
import load_icon from "../../assets/icons/Layer -16.png";
import message_icon from "../../assets/icons/Layer -19.png";
import mobile_icon from "../../assets/icons/Layer -3.png";
import { PreferredLanguages } from "../../constants/preferredLanguage";
import { useModal } from "../../providers/hooks/useModal";
import VoipCall from "../modal/content/VoipCall";
import RelatedAccounts from "./RelatedAccounts";
import styles from "./styles.module.css";

const options = ["Option 1", "Option 2", "Option 3", "option 4"];
const phoneRegex = /^\+27[0-9]{9}$/;
const invalidNumberMessage = "Phone number is not valid, please make sure to include country code +27";
const validationSchema = Yup.object().shape({
    email: Yup.string()
        // .required("Field is required")
        .email("This string must be email"),
    phone_home: Yup.string().matches(phoneRegex, invalidNumberMessage),
    phone_mobile: Yup.string().matches(phoneRegex, invalidNumberMessage),
    phone_work: Yup.string().matches(phoneRegex, invalidNumberMessage),
    phone_other: Yup.string().matches(phoneRegex, invalidNumberMessage),
});

function DebtorAccountDetails({ account_details, linked_accounts, activeItem, dispatch, linkedAccountView }) {
    const { setModal, setPersistent, setToast } = useModal();
    const [editMode, setEditMode] = React.useState(false);
    const [initialValues, setInitialValues] = React.useState({
        legal_case_no: "",
        prefered_language: "",
        account_number: "",
        phone_home: "",
        phone_work: "",
        phone_mobile: "",
        phone_other: "",
        email: "",
        physical_address: "",
        postal_address: "",
        phone_home_field: "",
        phone_work_field: "",
        phone_mobile_field: "",
        phone_other_field: "",
        debtor_name_editable: false,
        name: "",
        surname: ""
    });

    const handleEditDetails = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setEditMode(true);
    };

    const handleVoipCall = (phone_number) => {
        setPersistent({
            Component: <VoipCall phone_number={phone_number} account_number={account_details.account_number} />,
        });
    };
    const handleSendEmail = (email) => {
        setModal({
            Component: (
                <EmailTemplate
                    account_no={account_details.account_number}
                    debtor_name={account_details.name}
                    email={account_details.email}
                />
            ),
        });
    };
    const handleMessage = (phone_number) => {
        setModal({
            Component: (
                <ComposeMessage
                    phone_number={phone_number}
                    account_no={account_details.account_number}
                    debtor_name={account_details.name}
                />
            ),
        });
    };

    const handleViewRelatedAccount = () => {
        setModal({
            Component: (
                <RelatedAccounts
                    account_name={account_details.name + " " + account_details.surname}
                    linked_accounts={linked_accounts}
                    view_linked_account={linkedAccountView}
                />
            ),
        });
    };
    const handleAccountDetailsUpdate = (values) => {
        return new Promise((resolve, reject) => {
            const body = {
                ...Object.keys(values)
                    .filter((key) => !!values[key]) // "" or null
                    .reduce((obj, key) => {
                        obj[key] = values[key];
                        return obj;
                    }, {}),
                // convert naming
                account_no: values.account_number,
            };
            CaseService.updateDebtorCase(body)
                .then(() => {
                    setToast("Saved successfully.", "success");
                    for (const key in values) {
                        if (key in account_details && account_details[key] != null) {
                            account_details[key] = values[key];
                        }
                    }
                    setEditMode(false);
                    resolve(true);
                    dispatch("save");
                })
                .catch(() => {
                    setToast("We encountered an error.", "error");
                    reject(false);
                });
        });
    };

    const showAccountDetailsEditButton = () => {
        return (
            <div className={styles.icon_section}>
                {!editMode ? (
                    <IconButton type="button" onClick={handleEditDetails} size="large">
                        <img src={write_icon} alt="" />
                    </IconButton>
                ) : (
                    <IconButton type="submit" size="large">
                        <img src={load_icon} alt="" />
                    </IconButton>
                )}
            </div>
        );
    };

    React.useEffect(() => {
        if (account_details != null) {
            setInitialValues((state) => {
                for (const key in state) {
                    if (key in account_details) {
                        state[key] = account_details[key];
                    }
                }

                return { ...state };
            });
        }
    }, [account_details]);

    return (
        <div className={styles.personal_detail}>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={handleAccountDetailsUpdate}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    setValues,
                    setTouched,
                    resetForm,
                    isSubmitting,
                    touched,
                    values,
                }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <div className={styles.personal_detail_container}>
                            <div>
                                {account_details?.status === 1 ? showAccountDetailsEditButton() : null}
                                <Grid
                                    container
                                    style={{
                                        borderBottom: "1px solid #F0F0F0",
                                        position: "relative",
                                        marginBottom: 0,
                                    }}
                                    spacing={1}
                                    justifyContent="space-between"
                                    alignItems={"center"}
                                >
                                    <Grid item sm>
                                        <div className={styles.wrapper_section} style={{ marginLeft: "-10px" }}>
                                            <div className={styles.title}>
                                                Account No.
                                                <span>
                                                    <img
                                                        onClick={handleViewRelatedAccount}
                                                        src={link_icon}
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        alt=""
                                                    />
                                                </span>
                                            </div>

                                            <p className={clsx(styles.text, styles.address)}>
                                                {account_details?.account_number}
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item sm>
                                        <div className={styles.wrapper_section} style={{ marginLeft: "-10px" }}>
                                            <div className={styles.title}>ID</div>

                                            <p className={clsx(styles.text, styles.address)}>
                                                {account_details?.identification_number}
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item sm>

                                        <div className={styles.wrapper_section}>
                                            <div className={styles.title}>Name</div>
                                            {(!editMode) ? (
                                                <p className={clsx(styles.text, styles.address)}>
                                                    {account_details?.name}
                                                </p>
                                            ) : (account_details?.debtor_name_editable) ?
                                            
                                            (
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    name={"name"}
                                                    value={values.name}
                                                    error={touched.name && errors.name}
                                                    helperText={touched.name && errors.name}
                                                    onChange={handleChange}
                                                    isSearch={false}
                                                />
                                            ): ( <p className={clsx(styles.text, styles.address)}>
                                            {account_details?.name}
                                        </p>)}
                                        </div>
                                    </Grid>
                                    <Grid item sm>

                                        <div className={styles.wrapper_section}>
                                            <div className={styles.title}>Surname</div>
                                            {(!editMode && account_details?.debtor_name_editable) ? (
                                                <p className={clsx(styles.text, styles.address)}>
                                                    {account_details?.surname}
                                                </p>
                                            ) : (account_details?.debtor_name_editable) ? 
                                            (
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    name={"surname"}
                                                    value={values.surname}
                                                    error={touched.surname && errors.surname}
                                                    helperText={touched.surname && errors.surname}
                                                    onChange={handleChange}
                                                    isSearch={false}
                                                />
                                            ): (   <p className={clsx(styles.text, styles.address)}>
                                                    {account_details?.surname}
                                        </p>)
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item sm>
                                        <div className={styles.wrapper_section} >
                                            <div className={styles.title}>Legal Case No.</div>

                                            {!editMode ? (
                                                <p className={clsx(styles.text, styles.address)}>{account_details?.legal_case_no}</p>
                                            ) : (
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    id="legal_case_no"
                                                    name="legal_case_no"
                                                    customStyle={{
                                                        justifyContent: "flex-start",
                                                    }}
                                                    value={values.legal_case_no}
                                                    error={touched.legal_case_no && errors.legal_case_no}
                                                    helperText={touched.legal_case_no && errors.legal_case_no}
                                                    onChange={handleChange}
                                                    isSearch={false}
                                                />
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={1}
                                    style={{
                                        borderBottom: "1px solid #F0F0F0",
                                        marginBottom: 0,
                                    }}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Grid item sm>
                                        <div className={styles.wrapper_section} style={{ marginLeft: "-10px" }}>
                                            <div className={styles.title}>Preferred Language</div>

                                            {!editMode ? (
                                                <div className={styles.text}>{account_details?.prefered_language}</div>
                                            ) : (
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    id="prefered_language"
                                                    name="prefered_language"
                                                    select
                                                    value={values.prefered_language}
                                                    error={touched.prefered_language && errors.prefered_language}
                                                    helperText={touched.prefered_language && errors.prefered_language}
                                                    onChange={handleChange}
                                                >
                                                    {PreferredLanguages.map((language) => (
                                                        <MenuItem key={language} value={language}>
                                                            {language}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item sm>
                                        <div className={styles.wrapper_section}>
                                            <div className={styles.title}>
                                                Phone-
                                                {!editMode ? (
                                                    account_details?.phone_home_field != null ? (
                                                        account_details?.phone_home_field
                                                    ) : (
                                                        "home"
                                                    )
                                                ) : (
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        id="phone_home_field"
                                                        name="phone_home_field"
                                                        customStyle={{
                                                            justifyContent: "flex-start",
                                                        }}
                                                        value={values.phone_home_field}
                                                        error={touched.phone_home_field && errors.phone_home_field}
                                                        helperText={touched.phone_home_field && errors.phone_home_field}
                                                        onChange={handleChange}
                                                        isSearch={false}
                                                    />
                                                )}
                                            </div>

                                            {!editMode ? (
                                                <div className={styles.text}>
                                                    {account_details?.phone_home}
                                                    {activeItem == "contacts" && (
                                                        <>
                                                            <IconButton
                                                                disabled={account_details.phone_home == null}
                                                                onClick={() =>
                                                                    handleVoipCall(account_details?.phone_home)
                                                                }
                                                                size="large">
                                                                <img src={phone_icon} alt="call" />
                                                            </IconButton>
                                                            <IconButton
                                                                className={styles.phoneButton}
                                                                disabled={account_details.phone_home == null}
                                                                onClick={() =>
                                                                    handleMessage(account_details?.phone_home)
                                                                }
                                                                size="large">
                                                                <img src={message_icon} alt="call" />
                                                            </IconButton>
                                                        </>
                                                    )}
                                                </div>
                                            ) : (
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    name={"phone_home"}
                                                    customStyle={{
                                                        justifyContent: "flex-start",
                                                    }}
                                                    value={values.phone_home}
                                                    error={touched.phone_home && errors.phone_home}
                                                    helperText={touched.phone_home && errors.phone_home}
                                                    onChange={handleChange}
                                                    isSearch={false}
                                                />
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item sm>
                                        <div className={styles.wrapper_section}>
                                            <div className={styles.title}>
                                                Phone-
                                                {!editMode ? (
                                                    account_details?.phone_work_field != null ? (
                                                        account_details?.phone_work_field
                                                    ) : (
                                                        "work"
                                                    )
                                                ) : (
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        name={"phone_work_field"}
                                                        customStyle={{
                                                            justifyContent: "flex-start",
                                                        }}
                                                        value={values.phone_work_field}
                                                        error={touched.phone_work_field && errors.phone_work_field}
                                                        helperText={touched.phone_work_field && errors.phone_work_field}
                                                        onChange={handleChange}
                                                        isSearch={false}
                                                    />
                                                )}
                                            </div>

                                            {!editMode ? (
                                                <div className={styles.text}>
                                                    {account_details?.phone_work}
                                                    {activeItem == "contacts" && (
                                                        <>
                                                            <IconButton
                                                                className={styles.phoneButton}
                                                                disabled={account_details.phone_work == null}
                                                                onClick={() =>
                                                                    handleVoipCall(account_details?.phone_work)
                                                                }
                                                                size="large">
                                                                <img src={phone_icon} alt="call" />
                                                            </IconButton>
                                                            <IconButton
                                                                className={styles.phoneButton}
                                                                disabled={account_details.phone_work == null}
                                                                onClick={() =>
                                                                    handleMessage(account_details?.phone_work)
                                                                }
                                                                size="large">
                                                                <img src={message_icon} alt="call" />
                                                            </IconButton>
                                                        </>
                                                    )}
                                                </div>
                                            ) : (
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    name={"phone_work"}
                                                    customStyle={{
                                                        justifyContent: "flex-start",
                                                    }}
                                                    value={values.phone_work}
                                                    error={touched.phone_work && errors.phone_work}
                                                    helperText={touched.phone_work && errors.phone_work}
                                                    onChange={handleChange}
                                                    isSearch={false}
                                                />
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item sm>
                                        <div className={styles.wrapper_section}>
                                            <div className={styles.title}>
                                                Phone-
                                                {!editMode ? (
                                                    account_details?.phone_mobile_field != null ? (
                                                        account_details?.phone_mobile_field
                                                    ) : (
                                                        "mobile"
                                                    )
                                                ) : (
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        name={"phone_mobile_field"}
                                                        customStyle={{
                                                            justifyContent: "flex-start",
                                                        }}
                                                        value={values.phone_mobile_field}
                                                        error={touched.phone_mobile_field && errors.phone_mobile_field}
                                                        helperText={
                                                            touched.phone_mobile_field && errors.phone_mobile_field
                                                        }
                                                        onChange={handleChange}
                                                        isSearch={false}
                                                    />
                                                )}{" "}
                                            </div>
                                            {!editMode ? (
                                                <div className={styles.text}>
                                                    {account_details?.phone_mobile}
                                                    {activeItem == "contacts" && (
                                                        <>
                                                            <IconButton
                                                                className={styles.phoneButton}
                                                                disabled={account_details.phone_mobile == null}
                                                                onClick={() =>
                                                                    handleVoipCall(account_details?.phone_mobile)
                                                                }
                                                                size="large">
                                                                <img
                                                                    src={
                                                                        phone_icon //mobile_icon
                                                                    }
                                                                    alt="call"
                                                                />
                                                            </IconButton>
                                                            <IconButton
                                                                className={styles.phoneButton}
                                                                disabled={account_details.phone_mobile == null}
                                                                onClick={() =>
                                                                    handleMessage(account_details?.phone_mobile)
                                                                }
                                                                size="large">
                                                                <img src={message_icon} alt="call" />
                                                            </IconButton>
                                                        </>
                                                    )}
                                                </div>
                                            ) : (
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    name={"phone_mobile"}
                                                    customStyle={{
                                                        justifyContent: "flex-start",
                                                    }}
                                                    value={values.phone_mobile}
                                                    error={touched.phone_mobile && errors.phone_mobile}
                                                    helperText={touched.phone_mobile && errors.phone_mobile}
                                                    onChange={handleChange}
                                                    isSearch={false}
                                                />
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item sm>
                                        <div className={styles.wrapper_section}>
                                            <div className={styles.title}>
                                                Phone-
                                                {!editMode ? (
                                                    account_details?.phone_other_field != null ? (
                                                        account_details?.phone_other_field
                                                    ) : (
                                                        "other"
                                                    )
                                                ) : (
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        name={"phone_other_field"}
                                                        customStyle={{
                                                            justifyContent: "flex-start",
                                                        }}
                                                        value={values.phone_other_field}
                                                        error={touched.phone_other_field && errors.phone_other_field}
                                                        helperText={
                                                            touched.phone_other_field && errors.phone_other_field
                                                        }
                                                        onChange={handleChange}
                                                        isSearch={false}
                                                    />
                                                )}
                                            </div>

                                            {!editMode ? (
                                                <div className={styles.text}>
                                                    {account_details?.phone_other}
                                                    {activeItem == "contacts" && (
                                                        <>
                                                            <IconButton
                                                                className={styles.phoneButton}
                                                                disabled={account_details.phone_other == null}
                                                                onClick={() =>
                                                                    handleVoipCall(account_details?.phone_other)
                                                                }
                                                                size="large">
                                                                <img
                                                                    src={
                                                                        phone_icon //mobile_icon
                                                                    }
                                                                    alt="call"
                                                                />
                                                            </IconButton>
                                                            <IconButton
                                                                className={styles.phoneButton}
                                                                disabled={account_details.phone_other == null}
                                                                onClick={() =>
                                                                    handleMessage(account_details?.phone_other)
                                                                }
                                                                size="large">
                                                                <img src={message_icon} alt="call" />
                                                            </IconButton>
                                                        </>
                                                    )}
                                                </div>
                                            ) : (
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    name={"phone_other"}
                                                    customStyle={{
                                                        justifyContent: "flex-start",
                                                    }}
                                                    value={values.phone_other}
                                                    error={touched.phone_other && errors.phone_other}
                                                    helperText={touched.phone_other && errors.phone_other}
                                                    onChange={handleChange}
                                                    isSearch={false}
                                                />
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item sm>
                                        <div className={styles.wrapper_section}>
                                            <div className={styles.title}>Email</div>
                                            {!editMode ? (
                                                <div className={styles.text}>
                                                    {account_details?.email}
                                                    {activeItem == "contacts" && (
                                                        <IconButton
                                                            disabled={account_details.phone_home == null}
                                                            onClick={() => handleSendEmail(account_details?.email)}
                                                            size="large">
                                                            <img src={email_icon} alt="call" />
                                                        </IconButton>
                                                    )}
                                                </div>
                                            ) : (
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    name={"email"}
                                                    customStyle={{
                                                        justifyContent: "flex-start",
                                                    }}
                                                    value={values.email}
                                                    error={touched.email && errors.email}
                                                    helperText={touched.email && errors.email}
                                                    onChange={handleChange}
                                                    isSearch={false}
                                                />
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <Grid
                                container
                                spacing={1}
                                style={{
                                    borderBottom: "1px solid #F0F0F0",
                                    marginBottom: 0,
                                }}
                                alignItems="flex-start"
                            >
                                <Grid item md={6}>
                                    <div className={styles.wrapper_section}>
                                        <div className={styles.title}>Physical Address</div>
                                        {!editMode ? (
                                            <p className={clsx(styles.text, styles.break_word)}>
                                                {account_details?.physical_address}
                                            </p>
                                        ) : (
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                rows={4}
                                                name={"physical_address"}
                                                value={values.physical_address}
                                                error={touched.physical_address && errors.physical_address}
                                                helperText={touched.physical_address && errors.physical_address}
                                                onChange={handleChange}
                                                isSearch={false}
                                            />
                                        )}
                                    </div>
                                </Grid>
                                <Grid item md={6}>
                                    <div className={styles.wrapper_section}>
                                        <div className={styles.title}>Postal Address</div>
                                        {!editMode ? (
                                            <p className={clsx(styles.text, styles.address)}>
                                                {account_details?.postal_address}
                                            </p>
                                        ) : (
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                rows={4}
                                                name={"postal_address"}
                                                value={values.postal_address}
                                                error={touched.postal_address && errors.postal_address}
                                                helperText={touched.postal_address && errors.postal_address}
                                                onChange={handleChange}
                                                isSearch={false}
                                            />
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
}

export default DebtorAccountDetails;
